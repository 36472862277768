import { createEntityAdapter, EntityState } from '@ngrx/entity';
import {
  ContributorScheduleDto,
  WorkingSummaryDto,
} from '@swagger/humanresources';

interface WorkingSummaryDtoState {
  workingSummaryValues: WorkingSummaryDto[];
  workingSummaryMonthValues: WorkingSummaryDto;
}

export interface PlanState
  extends EntityState<ContributorScheduleDto>,
    WorkingSummaryDtoState {}

export const planAdapter = createEntityAdapter<ContributorScheduleDto>({
  selectId: (employeeSchedule) => employeeSchedule?.contributor?.uId,
  sortComparer: (a, b) =>
    a?.contributor?.lastName?.localeCompare(b.contributor?.lastName),
});

export const planInitialState: PlanState =
  planAdapter.getInitialState<WorkingSummaryDtoState>({
    workingSummaryValues: [],
    workingSummaryMonthValues: undefined
  });
